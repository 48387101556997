import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { fetchDataByType } from "../api/api";

const Main = () => {
  const [whatsappContact, setWhatsappContact] = useState("");
  const [telegramContact, setTelegramContact] = useState("");

  useEffect(() => {
    const fetchInitialData = async () => {
      const data = await fetchDataByType("extra");
      if (data) {
        setWhatsappContact(data.whatsappContact);
        setTelegramContact(data.telegramContact);
      }
    };

    fetchInitialData();
  }, []);

  return (
    <div className="main-bg">
      <div className="video-container">
        <ReactPlayer
          url="/gif.mp4"
          playing
          loop
          muted
          width="100%"
          height="100%"
          className="video-bg"
          style={{ objectFit: "cover" }}
          playsinline
        />
      </div>
      <div className="cards-container row d-flex justify-content-evenly m-0 p-3">
        <div className="header-fonts">
          <h5 className="text-contact">"Whatsapp" vəya "Teleqramdan"</h5>
          <h4 className="text-contact">əlaqə saxla</h4>
        </div>
        <a
          className="cards col-12 col-sm-6 col-md-6"
          href={`https://wa.me/${whatsappContact}?text=Salam%2C%20Extra%20oyun%20zalında%20oynamaq%20üçün%20ödəniş%20etmək%20istəyirəm.`}
        >
          <div className="cards wp-card row">
            <img
              className="col-4 col-md-2 col-sm-4 mt-3"
              src="/whatsapp_logo.png"
              alt=""
            />
            <div className="links-bg d-flex justify-content-around align-items-center">
              <h5 className="list-style">Whatsapp:</h5>
              <h6>{whatsappContact}</h6>
            </div>
          </div>
        </a>
        <a
          className="cards col-12 col-sm-6 col-md-6"
          href={`https://t.me/${telegramContact}?text=Salam%2C%20Extra%20oyun%20zalında%20oynamaq%20üçün%20ödəniş%20etmək%20istəyirəm.`}
        >
          <div className="cards tg-card row">
            <img
              className="col-4 col-md-2 col-sm-4 mt-3"
              src="/telegram_logo.png"
              alt=""
            />
            <div className="links-bg d-flex justify-content-around align-items-center">
              <h5 className="list-style">Telegram:</h5>
              <h6>{telegramContact}</h6>
            </div>
          </div>
        </a>
        <div className="row col-12 col-sm-6 m-0 p-0 d-flex justify-content-around align-items-center">
          <a
            href="https://chcplay1.net/"
            className="button-1 col-12 col-sm-5 mb-2 mt-2"
          >
            Oyna
          </a>
          <a
            href="https://app.chcplay.net/"
            className="button-2 col-12 col-sm-5 mb-2"
          >
            Oyunu yüklə
          </a>
        </div>
      </div>

      <div className="bottom-bg col-12">
        <div className="col-11 col-sm-6">
          <h6 className="text-video mt-3">Video təlimatı izlə</h6>
          <ReactPlayer
            url="https://www.youtube.com/embed/_u2p7-ZBmxE"
            controls
            width="100%"
            height="80%"
            className="bottom-video"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
